var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product || _vm.sim)?_c('b-card',{attrs:{"title":"Ubicación del serial"}},[_c('b-row',[(_vm.product && (_vm.product.product_inventory || _vm.product.product_enlistment))?_c('b-col',{attrs:{"md":"3"}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"light-success","size":"40"}},[_c('feather-icon',{attrs:{"icon":"ArchiveIcon","size":"25"}})],1)],1),_c('b-media-body',[_c('h5',{staticClass:"mb-0"},[_vm._v(" Estiba # ")]),_c('p',[_vm._v(_vm._s(_vm.product.product_enlistment ? _vm.product.product_enlistment.box.stowage.stowage_number : _vm.product.product_inventory.box.stowage.stowage_number))]),_c('p',[_vm._v(_vm._s(_vm.product.product_enlistment ? 'Estiba en alistamiento' : 'Estiba en inventario'))]),(_vm.product.product_enlistment)?_c('b-badge',{staticClass:"mr-1",attrs:{"pill":"","variant":_vm.product.product_enlistment.box.stowage.have_sim ? 'info' : 'warning'}},[_vm._v(" "+_vm._s(_vm.product.product_enlistment.box.stowage.have_sim ? 'Con SIM' : 'Sin SIM')+" ")]):_vm._e(),([1].includes(_vm.userData.user_profile_id))?_c('b-button',{attrs:{"variant":"secondary","size":"sm","to":{
          name: 'traceability', 
          params: { 
            loggable_type: 'estiba',
            loggable_id: _vm.product.product_enlistment ? _vm.product.product_enlistment.box.stowage.id : _vm.product.product_inventory.box.stowage.id,
          }
        }}},[_vm._v(" Trazabilidad ")]):_vm._e()],1)],1)],1):_vm._e(),(_vm.product && (_vm.product.product_inventory || _vm.product.product_enlistment))?_c('b-col',{attrs:{"md":"3"}},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"light-info","size":"40"}},[_c('feather-icon',{attrs:{"icon":"BoxIcon","size":"25"}})],1)],1),_c('b-media-body',[_c('h5',{staticClass:"mb-0"},[_vm._v(" Caja # ")]),_c('p',[_vm._v(_vm._s(_vm.product.product_enlistment ? _vm.product.product_enlistment.box.box_number : _vm.product.product_inventory.box.box_number))]),([1].includes(_vm.userData.user_profile_id))?_c('b-button',{attrs:{"variant":"secondary","size":"sm","to":{
            name: 'traceability', 
            params: { 
              loggable_type: 'caja',
              loggable_id: _vm.product.product_enlistment ? _vm.product.product_enlistment.box.id : _vm.product.product_inventory.box.id,
            }
          }}},[_vm._v(" Trazabilidad ")]):_vm._e()],1)],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"3"}},[(_vm.sim)?_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"light-warning","size":"40"}},[_c('feather-icon',{attrs:{"icon":"CreditCardIcon","size":"25"}})],1)],1),_c('b-media-body',[_c('h5',{staticClass:"mb-0"},[_vm._v(" Sim serial ")]),_c('p',[_vm._v(_vm._s(_vm.sim.sim_serial))]),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Operador: ")]),_vm._v(_vm._s(_vm.sim ? _vm.sim.operator : '--'))]),_c('p',[_c('b',[_vm._v("Tamaño: ")]),_vm._v(_vm._s(_vm.sim ? _vm.sim.size : '--'))])])],1):_vm._e()],1),_c('b-col',{attrs:{"md":"3"}},[(_vm.destination)?_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"","variant":"light-danger","size":"40"}},[_c('feather-icon',{attrs:{"icon":"NavigationIcon","size":"25"}})],1)],1),_c('b-media-body',[_c('h5',{staticClass:"mb-0"},[_vm._v(" Destino ")]),_c('p',[_c('b',[_vm._v("Nombre: ")]),_vm._v(" "+_vm._s(_vm.destination.name))]),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Dirección: ")]),_vm._v(_vm._s(_vm.destination.address))]),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Contacto: ")]),_vm._v(_vm._s(_vm.destination.contact_name))]),_c('p',{staticClass:"mb-0"},[_c('b',[_vm._v("Celular: ")]),_vm._v(_vm._s(_vm.destination.contact_phone))]),_c('p',[_c('b',[_vm._v("Ciudad: ")]),_vm._v(_vm._s(_vm.destination.city))])])],1):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }