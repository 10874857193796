var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.product)?_c('b-card',{attrs:{"title":"Resultado de Busqueda"}},[_c('div',{staticClass:"custom-search d-flex justify-content-end"},[_c('b-form-group',[_c('div',{staticClass:"d-flex align-items-center"},[_c('label',{staticClass:"mr-1"},[_vm._v("Buscar")]),_c('b-form-input',{staticClass:"d-inline-block",attrs:{"placeholder":"Buscar","type":"text"},model:{value:(_vm.searchTerm),callback:function ($$v) {_vm.searchTerm=$$v},expression:"searchTerm"}})],1)])],1),_c('vue-good-table',{attrs:{"columns":_vm.columns,"rows":[_vm.product],"rtl":_vm.direction,"search-options":{
      enabled: true,
      externalQuery: _vm.searchTerm },"select-options":{
      enabled: false,
      selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
      selectionInfoClass: 'custom-class',
      selectionText: 'rows selected',
      clearSelectionText: 'clear',
      disableSelectInfo: true, // disable the select info panel on top
      selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
    },"pagination-options":{
      enabled: true,
      perPage:_vm.pageLength
    }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'serial')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.serial))]),_c('br')]):(props.column.field === 'productType')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.product_type.name))])]):(props.column.field === 'numberOrder')?_c('span',{staticClass:"text-nowrap"},[_c('span',{staticClass:"text-nowrap"},[_vm._v(_vm._s(props.row.number_order))])]):(props.column.field === 'created_at')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateVariant(props.row.created_at))+" ")]):(props.column.field === 'updated_at')?_c('span',[_vm._v(" "+_vm._s(_vm.formatDateVariant(props.row.updated_at))+" ")]):(props.column.field === 'actions')?_c('span',[([1].includes(_vm.userData.user_profile_id))?_c('b-button',{attrs:{"variant":"secondary","size":"sm","to":{
            name: 'traceability', 
            params: { 
              loggable_type: 'producto',
              loggable_id: props.row.id,
            }
          }}},[_vm._v(" Trazabilidad ")]):_vm._e()],1):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}},{key:"pagination-bottom",fn:function(props){return [_c('div',{staticClass:"d-flex justify-content-between flex-wrap"},[_c('div',{staticClass:"d-flex align-items-center mb-0 mt-1"},[_c('span',{staticClass:"text-nowrap "},[_vm._v(" Mostrando 1 a ")]),_c('b-form-select',{staticClass:"mx-1",attrs:{"options":['10','20','30','40','50']},on:{"input":function (value){ return props.perPageChanged({currentPerPage:value}); }},model:{value:(_vm.pageLength),callback:function ($$v) {_vm.pageLength=$$v},expression:"pageLength"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v(" de "+_vm._s(props.total)+" items ")])],1),_c('div',[_c('b-pagination',{staticClass:"mt-1 mb-0",attrs:{"value":1,"total-rows":props.total,"per-page":_vm.pageLength,"first-number":"","last-number":"","align":"right","prev-class":"prev-item","next-class":"next-item"},on:{"input":function (value){ return props.pageChanged({currentPage:value}); }},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}],null,true)})],1)])]}}],null,false,2702737885)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }