<template>

  <section class="invoice-preview-wrapper">

    <hr class="mb-3">

    <h4>SIM asociada a un pedido</h4>
  
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      v-if="order === null"
    >
      <h4 class="alert-heading">
        No hay información de pedido
      </h4>
    </b-alert>

    <b-row
      v-if="order"
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
  
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
  
              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h2 class="text-primary invoice-logo">
                    GRUPO LOGISTICO PETRO SAS 
                  </h2>
                </div>
                <h5 class="mb-2">
                  ORDEN DE ENTREGA DE SIMs
                </h5>
                <p class="card-text mb-25">
                  CRA 106 #15 A 25 LT 31 MZ 5BG 18 Y 19 
                </p>
                <p class="card-text mb-25">
                  ZONA FRANCA BOGOTA / BODEGA DELOING
                </p>
                <p class="card-text mb-25">
                  Bogotá D.C., Colombia
                </p>
                <!-- <p class="card-text mb-0">
                  +57 31526233556
                </p> -->
              </div>
  
              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Pedido
                  <span class="invoice-number"> #{{ order.id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Estado:
                  </p>
                  <p class="invoice-date">
                    <b-badge :variant="statusVariant(order.order_status.name)">
                      {{ order.order_status.name }}
                    </b-badge>
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Fecha:
                  </p>
                  <p class="invoice-date">
                    {{ formatDateVariant(order.created_at) }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Actualización:
                  </p>
                  <p class="invoice-date">
                    {{ formatDateVariant(order.updated_at) }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>
  
          <!-- Spacer -->
          <hr class="invoice-spacing">
  
          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">
  
              <!-- Col: Invoice To -->
              <b-col
                v-if="order.destination"
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Cliente:
                </h6>
                <h6 class="mb-25">
                  {{ order.destination.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ order.destination.contact_name }}
                </p>
                <p class="card-text mb-25">
                  {{ order.destination.address }}, {{ order.destination.city }}
                </p>
                <p class="card-text mb-25">
                  Celular: {{ order.destination.contact_phone }}
                </p>
              </b-col>
  
              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <!-- <div>
                  <h6 class="mb-2">
                    Información adicional:
                  </h6>
                  <span class="ml-75">{{ order.additional_info }}</span>
                </div> -->
              </b-col>
            </b-row>
          </b-card-body>
  
          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :items="[1]"
            :fields="['referencia', 'unidades', 'descripcion']"
          >
            <template #cell(referencia)="data">
              <b-card-text class="font-weight-bold mb-25">
                SIMs
              </b-card-text>
            </template>
  
            <template #cell(unidades)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ order.amount }}
              </b-card-text>
            </template>
  
            <template #cell(descripcion)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ order.description }}
              </b-card-text>
            </template>
            
          </b-table-lite>
  
          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>
  
              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="6"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold text-primary">Requerimientos especiales:</span>
                  <span class="ml-75">{{ order.description }}</span>
                </b-card-text>
              </b-col>
            </b-row>
          </b-card-body>
  
          <!-- Spacer -->
          <hr class="invoice-spacing">
  
          <!-- Note -->
          <b-card-body v-if="order.deliverer" class="invoice-padding pt-0">
            <span class="font-weight-bold text-primary">Conductor: </span> <br>
            <br>
            <span> Placas: {{ order.deliverer.plate }}</span> <br>
            <span> Conductor: {{ order.deliverer.name }}</span> <br>
            <span> CC: {{ order.deliverer.document_number }}</span> <br>
          </b-card-body>
        </b-card>

        <!-- Action Buttons -->
        <b-button
          variant="info"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
          :to="{ name: 'sim-order-detail', params: { sim_order_id: order.id }}"
        >
          Ver detalle del pedido
        </b-button>
      </b-col>
  
    </b-row>
  
  </section>
  
  </template>
  
  <script>
  import {
    BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardBody, BCardHeader, BCardTitle, BCardText, BFormCheckbox, BTableLite, BBadge, BAlert, BLink, BSpinner
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
  import axios from 'axios'
  import { required, } from '@validations'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Logo from '@/@core/layouts/components/Logo.vue'
  import {constants} from "@/helpers/constants"
  import moment from 'moment';
  import {formatDate} from "@/helpers/utils"
  
  export default {
    components: {
      BButton,
      BMedia,
      BAvatar,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BTable,
      BCard,
      BCardBody,
      BCardHeader,
      BCardTitle,
      BCardText,
      BFormCheckbox,
      BTableLite,
      BBadge,
      BAlert,
      BLink,
      BSpinner,
      vSelect,
      ValidationProvider,
      ValidationObserver,
      Logo,
    },
    data() {
      return {
        host: constants.hostUrl,
        accessToken: localStorage.getItem('accessToken'),
        userData: JSON.parse(localStorage.getItem('userData')),
        products: null,
        loading: false,
        required,
        invoiceData: null,
        status: [{
          1: 'En alistamiento',
          2: 'Despachado',
          3: 'Cancelado',
          4: 'Finalizado',
          5: 'Recibido',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
      }
    },
    props: {
      order: {
        type: Object,
        required: true,
      },
    },
    created() {
    },
    computed: {
      statusVariant() {
        const statusColor = {
          /* eslint-disable key-spacing */
          'En alistamiento'      : 'light-primary',
          Despachado : 'light-success',
          Cancelado     : 'light-danger',
          Finalizado     : 'light-warning',
          Recibido      : 'light-info',
          /* eslint-enable key-spacing */
        }
  
        return status => statusColor[status]
      },
      formatDateVariant() {
        return date => formatDate(date)
      },
    },
    methods: {
      
    },
  }
  </script>
  
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
  
  <style lang="scss" scoped>
  @import "~@core/scss/base/pages/app-invoice.scss";
  </style>
  
  <style lang="scss">
  @media print {
  
    // Global Styles
    body {
      background-color: transparent !important;
    }
    nav.header-navbar {
      display: none;
    }
    .main-menu {
      display: none;
    }
    .header-navbar-shadow {
      display: none !important;
    }
    .content.app-content {
      margin-left: 0;
      padding-top: 2rem !important;
    }
    footer.footer {
      display: none;
    }
    .card {
      background-color: transparent;
      box-shadow: none;
    }
    .customizer-toggle {
      display: none !important;
    }
  
    // Invoice Specific Styles
    .invoice-preview-wrapper {
      .row.invoice-preview {
        .col-md-8 {
          max-width: 100%;
          flex-grow: 1;
        }
  
        .invoice-preview-card {
          .card-body:nth-of-type(2) {
            .row {
                > .col-12 {
                max-width: 50% !important;
              }
  
              .col-12:nth-child(2) {
                display: flex;
                align-items: flex-start;
                justify-content: flex-end;
                margin-top: 0 !important;
              }
            }
          }
        }
      }
  
      // Action Right Col
      .invoice-actions {
        display: none;
      }
    }
  }
  </style>
  
  