<template>
  <div>

    <!-- User Info: Input Fields -->
    <validation-observer ref="formSearchProducts">

      <b-form
        @submit.prevent="searchProduct">
        <b-row>

          <!-- Field: Username -->
          <b-col
            cols="12"
            md="4"
          >

            <b-form-group
                label="SIM"
                label-for="sim"
              >
              <validation-provider
                  #default="{ errors }"
                  name="SIM"
                  rules="required"
                >

                <b-form-input
                  id="sim"
                  v-model="sim_serial"
                />

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>

          </b-col>
          
        </b-row>

        <!-- Action Buttons -->
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          Buscar
        </b-button>
      
      </b-form>
    </validation-observer>

    <search-location-products-card :product="product" :sim="sim" :destination="destination"></search-location-products-card>
    <search-product-table v-if="product" :product="product"></search-product-table>
    <order-by-sim v-if="simOrder" :order="simOrder"></order-by-sim>
    
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, 
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'
import { required, } from '@validations'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SearchLocationProductsCard from './SearchLocationProductsCard.vue'
import SearchProductTable from './SearchProductTable.vue'
import OrderBySim from './OrderBySim.vue'
import {constants} from "@/helpers/constants"

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    ValidationProvider,
    ValidationObserver,

    SearchProductTable,
    OrderBySim,
    SearchLocationProductsCard,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      serial: '',
      sim_serial: '',
      product: null,
      order: null,
      simOrder: null,
      orderRecord: null,
      sim: null,
      destination: null,
      required,
    }
  },
  setup(props) {
    const countryOptions = [
      { label: 'Colombia', value: 1 },
    ]

    const serializedOptions = [
      { label: 'Texto', value: 0 },
      { label: 'JSON', value: 1 },
    ]

    return {
      countryOptions,
      serializedOptions,
    }
  },
  created() {
  },
  methods: {
    searchProduct() {
      this.product = null
      this.order = null
      this.sim = null
      this.destination = null

      event.preventDefault()
      this.$refs.formSearchProducts.validate().then(success => {
        if (success) {

          let headers = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + this.accessToken
          }
          axios.post(this.host + '/api/front-web/search-product-by-sim',{
            sim_serial: this.sim_serial,
          }, { headers }).then(response => {
            console.log('search-product-by-sim: ', response)
            this.simOrder = null

            if(response.data.status) {
              this.orderRecord = response.data.data.orderRecord
              this.order = response.data.data.order
              this.product = response.data.data.product

              this.sim = response.data.data.sim
              if(this.sim && this.sim.sim_order_record) {
                this.simOrder = this.sim.sim_order_record.sim_order
              }
              
              this.destination = this.order.destination
            } else {
              this.$toast({
                component: ToastificationContentVue,
                position: 'top-right',
                props: {
                  title: `Advertencia`,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                  text: response.data.message,
                },
              })
            }
          }).catch( err => {
            console.log(err)
          })

        }
      })


    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
