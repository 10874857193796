<template>

  <div>

    <b-row class="match-height">
      <!-- <b-col
        xl="4"
        md="6"
      >
        <ecommerce-medal :data="data.congratulations" />
      </b-col> -->
      <b-col
        xl="12"
        md="12"
      >
        <!-- <ecommerce-statistics :data="data.statisticsItems" /> -->
        <general-stats></general-stats>

      </b-col>
    </b-row>

    <b-card>

      <b-row>
        <b-col cols="12">

          <b-tabs
            pills
          >

            <!-- Tab: Account -->
            <b-tab active>
              <template #title>
                <feather-icon
                  icon="SearchIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Buscar Serial</span>
              </template>
              <search-products-card 
                class="mt-2 pt-75"
              />
            </b-tab>

            <!-- Tab: Account -->
            <b-tab >
              <template #title>
                <feather-icon
                  icon="SmartphoneIcon"
                  size="16"
                  class="mr-0 mr-sm-50"
                />
                <span class="d-none d-sm-inline">Buscar SIM</span>
              </template>
              <search-sim-card 
                class="mt-2 pt-75"
              />
            </b-tab>
            
          </b-tabs>

          
        </b-col>
      </b-row>

    </b-card>

  </div>

</template>

<script>
import router from '@/router'
import { BRow, BCol, BTabs, BTab, BCard } from 'bootstrap-vue'
import SearchProductsCard from './SearchProductsCard.vue'
import SearchSimCard from './SearchSimCard.vue'
import GeneralStats from '../../statistics/general/GeneralStats.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BTab,
    BCard,

    SearchProductsCard,
    SearchSimCard,
    GeneralStats,
  },
  data() {
    return {
    }
  },
  created() {
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>