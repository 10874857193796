<template>
  <b-card 
    title="Ubicación del serial"
    v-if="product || sim"
  >

  <b-row>
    <b-col v-if="product && (product.product_inventory || product.product_enlistment)"
      md="3">
      <b-media
      no-body>
      <b-media-aside class="mr-1">
        <b-avatar
          rounded
          variant="light-success"
          size="40"
        >
          <feather-icon
            icon="ArchiveIcon"
            size="25"
          />
        </b-avatar>
      </b-media-aside>
      <b-media-body>
        <h5 class="mb-0">
          Estiba #
        </h5>
        <p>{{ product.product_enlistment ? product.product_enlistment.box.stowage.stowage_number : product.product_inventory.box.stowage.stowage_number }}</p>
        <p>{{ product.product_enlistment ? 'Estiba en alistamiento' : 'Estiba en inventario' }}</p>
        <b-badge
          v-if="product.product_enlistment"
          pill
          :variant="product.product_enlistment.box.stowage.have_sim ? 'info' : 'warning'"
          class="mr-1"
        >
          {{ product.product_enlistment.box.stowage.have_sim ? 'Con SIM' : 'Sin SIM' }}
        </b-badge>


        <b-button
          v-if="[1].includes(userData.user_profile_id)"
          variant="secondary"
          size="sm"
          :to="{
            name: 'traceability', 
            params: { 
              loggable_type: 'estiba',
              loggable_id: product.product_enlistment ? product.product_enlistment.box.stowage.id : product.product_inventory.box.stowage.id,
            }
          }"
        >
          Trazabilidad
        </b-button>

      </b-media-body>
    </b-media>
    </b-col>
    <b-col v-if="product && (product.product_inventory || product.product_enlistment)"
      md="3">
      <b-media
        no-body>
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-info"
            size="40"
          >
            <feather-icon
              icon="BoxIcon"
              size="25"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            Caja #
          </h5>
          <p>{{ product.product_enlistment ? product.product_enlistment.box.box_number : product.product_inventory.box.box_number }}</p>

          <b-button
            v-if="[1].includes(userData.user_profile_id)"
            variant="secondary"
            size="sm"
            :to="{
              name: 'traceability', 
              params: { 
                loggable_type: 'caja',
                loggable_id: product.product_enlistment ? product.product_enlistment.box.id : product.product_inventory.box.id,
              }
            }"
          >
            Trazabilidad
          </b-button>

        </b-media-body>
      </b-media>
    </b-col>
    <b-col md="3">
      <b-media
        v-if="sim"
        no-body>
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-warning"
            size="40"
          >
            <feather-icon
              icon="CreditCardIcon"
              size="25"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            Sim serial
          </h5>
          <p>{{ sim.sim_serial }}</p>
          <p class="mb-0"><b>Operador: </b>{{ sim ? sim.operator : '--' }}</p>
          <p><b>Tamaño: </b>{{ sim ? sim.size : '--' }}</p>
        </b-media-body>
      </b-media>
    </b-col>
    <b-col md="3">
      <b-media
        v-if="destination"
        no-body>
        <b-media-aside class="mr-1">
          <b-avatar
            rounded
            variant="light-danger"
            size="40"
          >
            <feather-icon
              icon="NavigationIcon"
              size="25"
            />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            Destino
          </h5>
          <p><b>Nombre: </b> {{ destination.name }}</p>
          <p class="mb-0"><b>Dirección: </b>{{ destination.address }}</p>
          <p class="mb-0"><b>Contacto: </b>{{ destination.contact_name }}</p>
          <p class="mb-0"><b>Celular: </b>{{ destination.contact_phone }}</p>
          <p><b>Ciudad: </b>{{ destination.city }}</p>
        </b-media-body>
      </b-media>
    </b-col>
  </b-row>

  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BAvatarGroup, VBTooltip, BMediaBody, BMedia, BMediaAside, BRow, BCol, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'
import {constants} from "@/helpers/constants"
import {formatDate} from "@/helpers/utils"

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BAvatarGroup, 
    VBTooltip, 
    BMediaBody, 
    BMedia, 
    BMediaAside,
    BRow,
    BCol,
    BButton,
  },
  data() {
    return {
      host: constants.hostUrl,
      accessToken: localStorage.getItem('accessToken'),
      userData: JSON.parse(localStorage.getItem('userData')),
      products: [],
      order: null,
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: 'ID',
          field: 'id',
        },
        {
          label: 'Serial',
          field: 'serial',
        },
        {
          label: 'Tipo de producto',
          field: 'productType',
        },
        {
          label: 'Numero de transporte',
          field: 'numberOrder',
        },
        {
          label: 'Creado',
          field: 'created_at',
        },
        {
          label: 'Actualizado',
          field: 'updated_at',
        },
      ],
      searchTerm: '',
      status: [{
        1: 'En alistamiento',
        2: 'Despachado',
        3: 'Cancelado',
        4: 'Finalizado',
        5: 'Recibido',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
    }
  },
  props: {
    product: {
      type: Object,
      required: false,
    },
    sim: {
      type: Object,
      required: false,
    },
    destination: {
      type: Object,
      required: false,
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        'En alistamiento'      : 'light-primary',
        Despachado : 'light-success',
        Cancelado     : 'light-danger',
        Finalizado     : 'light-warning',
        Recibido      : 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    formatDateVariant() {
      return date => formatDate(date)
    },
  },
  created() {

  },
  methods: {
    
  },
}
</script>
